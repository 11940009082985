import { parseISO } from "date-fns";
import * as React from "react";
import Layout from "../components/Layout";
import articles from "../../content/articles.json";
import { OutboundLink } from "gatsby-plugin-google-gtag";

articles.sort(
  (a, b) => parseISO(b.modified).valueOf() - parseISO(a.modified).valueOf()
);

const articlesPerPage = 30;

const IndexPage = () => {
  return (
    <Layout>
      <ul>
        {articles.slice(0, articlesPerPage).map((article) => (
          <li key={article.articleUrl}>
            <OutboundLink
              href={article.articleUrl}
              target="_blank"
              className="inline-block hover:underline"
              rel="noreferrer"
            >
              {article.articleTitle}
            </OutboundLink>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export default IndexPage;

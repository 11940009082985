import * as React from "react";
import { Helmet } from "react-helmet";

const siteTitle = "てくぶろアンテナ";
const description = "人気テックブログとITニュースサイトの更新アンテナ";

const footerContent = `
©2021- てくぶろアンテナ管理人
`;

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <html
          lang="ja"
          prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# website: http://ogp.me/ns/website#"
        />
        <head></head>
        <meta charSet="utf-8"></meta>
        <title>{siteTitle}</title>
        <meta name="description" content={description}></meta>

        <body className="bg-gray-50"></body>
      </Helmet>
      <main>{children}</main>
      <footer className="text-right">{footerContent}</footer>
    </>
  );
}
